<template>
  <b-card

    title="Import shipments CSV"
  >
    <b-form @submit.prevent>
      <b-row>

        <b-col cols="12">
          <b-form-group
            label="CSV File"
            label-for="v-csv-file"
          >

            <b-form-file
              v-model="csvFile"
              :state="Boolean(csvFile)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".csv"
              required
            />
            <div class="mt-3">
              Selected file: {{ csvFile ? csvFile.name : '' }}
            </div>

          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="importShipments"
          >
            Import
          </b-button>

          <router-link :to="{name:'shipments'}">

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >

              Back
            </b-button>

          </router-link>

        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormFile, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      csvFile: null,
    }
  },
  methods: {
    importShipments() {
      if (!this.csvFile) {
        return
      }

      const formData = new FormData()
      formData.append('file', this.csvFile)

      this.$store.dispatch('shipment/import', formData)
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: `${result.data.total} items imported successfully`,
              variant: 'success',
            },
          })

          this.$router.push({ name: 'shipments' })
        },
        error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: error.response?.data?.message || 'Error while importing file',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
